import store from '../store'

import { tracker } from '@/plugins/vue-any'

function createChildren (name, path) {
  return [
    {
      path: '',
      name: `${name}.start`,
      component: () => import('@/pages/CheckoutPages/layouts/Start')
    },
    {
      path: 'registration/:offerId',
      name: `${name}.register`,
      component: () => import('@/pages/CheckoutPages/layouts/Registration'),
      meta: {
        auth: false
      },
      beforeEnter (to, from, next) {
        const isaAuth = store.getters['auth/isAuthenticated']
        if (isaAuth) {
          return next(path)
        }
        next()
      }
    },
    {
      path: 'payment/:offerId',
      name: `${name}.payment`,
      component: () => import('@/pages/CheckoutPages/layouts/Payment')
    }
  ]
}

export const checkouts = [

  // Checkout
  {
    path: '/checkout',
    name: 'offers.index',
    component: () => import('@/pages/Offers/Index.vue'),
    meta: {
      premium_only: true
    }
  },
  {
    path: '/payment/:offerId',
    name: 'payment.index',
    component: () => import('@/pages/Payment/Index.vue'),
    meta: {
      premium_only: true
    }
  },

  // Checkout/v2
  {
    path: '/checkout/v2',
    name: 'checkout.index',
    component: () => import('@/pages/Checkout/Index.vue'),
    meta: {
      premium_only: true,
      auth: true,
      type: 'regular',
      experiment_layer: 'class'
    }
  },
  {
    path: '/payment/v2/:offerId',
    name: 'payment.index',
    component: () => import('@/pages/PaymentV2/Index.vue'),
    meta: {
      premium_only: true,
      auth: true,
      type: 'regular',
      experiment_layer: 'class'
    }
  },
  {
    path: '/checkout/v3',
    name: 'checkout_v3.index',
    component: () => import('@/pages/CheckoutV3/Index.vue'),
    meta: {
      premium_only: true,
      auth: true,
      experiment_layer: 'class'
    }
  },
  {
    path: '/payment/v3/:offerId',
    name: 'payment_v3.index',
    component: () => import('@/pages/PaymentV3/Index.vue'),
    meta: {
      premium_only: true,
      auth: true,
      experiment_layer: 'class'
    }
  },

  // Holiday Offers
  {
    path: '/promo70',
    name: 'checkoutHoliday.index',
    component: () => import('@/pages/CheckoutHoliday/Index.vue'),
    meta: {
      premium_only: true,
      auth: true,
      type: 'promo70',
      experiment_layer: 'class'
    }
  },
  {
    path: '/promo70/:offerId',
    name: 'holidayPayment.index',
    component: () => import('@/pages/PaymentHoliday/Index.vue'),
    meta: {
      premium_only: true,
      auth: true,
      experiment_layer: 'class',
      type: 'promo70'
    }
  },
  {
    path: '/email/promo50',
    name: 'emailPromo50.index',
    component: () => import('@/pages/CheckoutEmailPromo50/Index.vue'),
    meta: {
      premium_only: true,
      auth: true,
      type: 'email50',
      experiment_layer: 'class'
    }
  },
  {
    path: '/promo50',
    name: 'checkoutPromo50.index',
    component: () => import('@/pages/CheckoutPromo50/Index.vue'),
    meta: {
      premium_only: true,
      auth: true,
      type: 'promo50',
      experiment_layer: 'class'
    }
  },
  {
    path: '/email/promo70',
    name: 'checkoutPromo70.index',
    component: () => import('@/pages/CheckoutPromo70/Index.vue'),
    meta: {
      premium_only: true,
      auth: true,
      type: 'email70',
      experiment_layer: 'class'
    }
  },
  {
    path: '/promonotrial70',
    name: 'checkoutHolidayNotrial.index',
    component: () => import('@/pages/PromoNoTrial70/Index.vue'),
    meta: {
      premium_only: true,
      auth: true,
      type: 'promonotrial70',
      experiment_layer: 'class'
    }
  },

  {
    path: '/prices',
    name: 'prices.index',
    component: () => import('@/pages/CheckoutV4/Index.vue'),
    meta: {
      auth: false,
      type: 'external_checkout',
      experiment_layer: 'external'
    }
  },
  {
    path: '/checkout/v5',
    name: 'checkout_v5.index',
    component: () => import(
      '@/pages/CheckoutV5/Index'
    ),
    meta: {
      auth: false,
      type: 'external_promo_checkout',
      experiment_layer: 'class'
    }
  },
  {
    path: '/payment/v5/:offerId',
    name: 'payment_v5.index',
    component: () => import(
      '@/pages/PaymentV5/Index'
    ),
    meta: {
      premium_only: true,
      type: 'external_promo_checkout',
      experiment_layer: 'class'
    }
  },
  {
    path: '/registration/:offerId',
    name: 'registration.index',
    component: () => import(
      '@/pages/Registration/Index'
    ),
    meta: {
      auth: false
    },
    beforeEnter (to, from, next) {
      const isaAuth = store.getters['auth/isAuthenticated']
      if (isaAuth) {
        next('/checkout/v5')
      }
      next()
    }
  },
  {
    path: '/promoday',
    name: 'promoday.index',
    component: () => import(
      '@/pages/PromoDay/Index'
    ),
    meta: {
      auth: false,
      type: 'external_promo_notrial_checkout',
      experiment_layer: 'external'
    }
  },
  {
    path: '/paymentday/:offerId',
    name: 'paymentDay.index',
    component: () => import(
      '@/pages/PaymentDay/Index'
    ),
    meta: {
      premium_only: true,
      type: 'external_promo_notrial_checkout',
      experiment_layer: 'class',
      hasTrial: false
    }
  },

  {
    path: '/ewagift/',
    name: 'ewaGift.index',
    component: () => import(
      '@/pages/CheckoutPages/EwaGift/Index'
    ),
    children: [
      {
        path: '',
        name: 'ewaGift.start',
        component: () => import('@/pages/CheckoutPages/EwaGift/Start')
      },
      {
        path: 'success',
        name: 'ewaGift.success',
        component: () => import('@/pages/CheckoutPages/EwaGift/Success')
      },
      {
        path: 'payment/:offerId',
        name: 'ewaGift.payment',
        component: () => import('@/pages/CheckoutPages/EwaGift/Payment')
      }
    ],
    meta: {
      type: 'external_ewagift',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false
    }
  },
  {
    path: '/ewagiftweb/',
    name: 'ewaGiftWeb.index',
    component: () => import(
      '@/pages/CheckoutPages/EwaGiftWeb/Index'
    ),
    children: [
      {
        path: '',
        name: 'EwaGiftWeb.start',
        component: () => import('@/pages/CheckoutPages/EwaGift/Start')
      },
      {
        path: 'success',
        name: 'EwaGiftWeb.success',
        component: () => import('@/pages/CheckoutPages/EwaGift/Success')
      },
      {
        path: 'payment/:offerId',
        name: 'EwaGiftWeb.payment',
        component: () => import('@/pages/CheckoutPages/EwaGift/Payment')
      }
    ],
    meta: {
      type: 'ewagift_web',
      experiment_layer: 'class',
      hasTrial: false,
      auth: false
    }
  },

  {
    path: '/payment/babbel/:offerId',
    name: 'payment_babbel.index',
    component: () => import('@/pages/PaymentBabbel/Index.vue'),
    meta: {
      premium_only: true,
      auth: true,
      type: 'babbel',
      experiment_layer: 'class'
    }
  },

  {
    path: '/welcome1/',
    name: 'welcome1.index',
    component: () => import(
      '@/pages/CheckoutPages/Welcome/Welcome1/Index'
    ),
    children: [
      {
        path: '',
        name: 'welcome1.start',
        component: () => import('@/pages/CheckoutPages/Welcome/Welcome1/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'welcome1.register',
        component: () => import('@/pages/CheckoutPages/Welcome/Welcome1/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isaAuth = store.getters['auth/isAuthenticated']
          if (isaAuth) {
            return next('/welcome1/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'welcome1.payment',
        component: () => import('@/pages/CheckoutPages/Welcome/Welcome1/Payment')
      }
    ],
    meta: {
      type: 'external_welcome_1',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false
    }
  },
  {
    path: '/welcome2/',
    name: 'welcome2.index',
    component: () => import(
      '@/pages/CheckoutPages/Welcome/Welcome2/Index'
    ),
    children: [
      {
        path: '',
        name: 'welcome2.start',
        component: () => import('@/pages/CheckoutPages/Welcome/Welcome2/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'welcome2.register',
        component: () => import('@/pages/CheckoutPages/Welcome/Welcome2/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isaAuth = store.getters['auth/isAuthenticated']
          if (isaAuth) {
            return next('/welcome2/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'welcome2.payment',
        component: () => import('@/pages/CheckoutPages/Welcome/Welcome2/Payment')
      }
    ],
    meta: {
      type: 'external_welcome_2',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false
    }
  },
  {
    path: '/welcome3/',
    name: 'welcome3.index',
    component: () => import(
      '@/pages/CheckoutPages/Welcome/Welcome3/Index'
    ),
    children: [
      {
        path: '',
        name: 'welcome3.start',
        component: () => import('@/pages/CheckoutPages/Welcome/Welcome3/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'welcome3.register',
        component: () => import('@/pages/CheckoutPages/Welcome/Welcome3/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isaAuth = store.getters['auth/isAuthenticated']
          if (isaAuth) {
            return next('/welcome3/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'welcome3.payment',
        component: () => import('@/pages/CheckoutPages/Welcome/Welcome3/Payment')
      }
    ],
    meta: {
      type: 'external_welcome_3',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false
    }
  },
  {
    path: '/welcome4/',
    name: 'welcome4.index',
    component: () => import(
      '@/pages/CheckoutPages/Welcome/Welcome4/Index'
    ),
    children: [
      {
        path: '',
        name: 'welcome4.start',
        component: () => import('@/pages/CheckoutPages/Welcome/Welcome4/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'welcome4.register',
        component: () => import('@/pages/CheckoutPages/Welcome/Welcome4/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isaAuth = store.getters['auth/isAuthenticated']
          if (isaAuth) {
            return next('/welcome3/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'welcome4.payment',
        component: () => import('@/pages/CheckoutPages/Welcome/Welcome4/Payment')
      }
    ],
    meta: {
      type: 'external_welcome_4',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false
    }
  },

  {
    path: '/valentine/',
    name: 'valentine.index',
    component: () => import(
      '@/pages/CheckoutPages/Valentine/Index'
    ),
    children: [
      {
        path: '',
        name: 'valentine.start',
        component: () => import('@/pages/CheckoutPages/Valentine/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'valentine.register',
        component: () => import('@/pages/CheckoutPages/Valentine/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isaAuth = store.getters['auth/isAuthenticated']
          if (isaAuth) {
            return next('/valentine/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'valentine.payment',
        component: () => import('@/pages/CheckoutPages/Valentine/Payment')
      }
    ],
    meta: {
      type: 'external_valentine',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false
    }
  },
  {
    path: '/valentine-web/',
    name: 'valentineWeb.index',
    component: () => import(
      '@/pages/CheckoutPages/Valentine/Index'
    ),
    children: [
      {
        path: '',
        name: 'valentineWeb.start',
        component: () => import('@/pages/CheckoutPages/Valentine/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'valentineWeb.register',
        component: () => import('@/pages/CheckoutPages/Valentine/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isaAuth = store.getters['auth/isAuthenticated']
          if (isaAuth) {
            return next('/valentine-web/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'valentineWeb.payment',
        component: () => import('@/pages/CheckoutPages/Valentine/Payment')
      }
    ],
    meta: {
      type: 'external_valentine_web',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false
    }
  },
  {
    path: '/valentine-inst/',
    name: 'valentineInst.index',
    beforeEnter (to, from, next) {
      switch (tracker.getConfigStringValue('external_page', '')) {
        case 'external_valentine_inst_control':
          return next({ name: 'valentineControl.start' })
        case 'external_valentine_inst_group_b':
          return next({ name: 'valentineGroupB.start' })
        default:
          next()
      }
    },
    component: () => import(
      '@/pages/CheckoutPages/Valentine/Index'
    ),
    children: [
      {
        path: '',
        name: 'valentineInst.start',
        component: () => import('@/pages/CheckoutPages/Valentine/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'valentineInst.register',
        component: () => import('@/pages/CheckoutPages/Valentine/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isaAuth = store.getters['auth/isAuthenticated']
          if (isaAuth) {
            return next('/valentine-inst/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'valentineInst.payment',
        component: () => import('@/pages/CheckoutPages/Valentine/Payment')
      }
    ],
    meta: {
      type: 'external_valentine_inst',
      experiment_layer: 'external',
      experimentId: 'web_testvalentinereg_200223',
      hasTrial: false,
      auth: false
    }
  },
  {
    path: '/valentine-control/',
    name: 'valentineControl.index',
    component: () => import(
      '@/pages/CheckoutPages/Valentine/Index'
    ),
    children: [
      {
        path: '',
        name: 'valentineControl.start',
        component: () => import('@/pages/CheckoutPages/Valentine/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'valentineControl.register',
        component: () => import('@/pages/CheckoutPages/Valentine/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isaAuth = store.getters['auth/isAuthenticated']
          if (isaAuth) {
            return next('/valentine-control/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'valentineControl.payment',
        component: () => import('@/pages/CheckoutPages/Valentine/Payment')
      }
    ],
    meta: {
      type: 'external_valentine_inst_control',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false
    }
  },
  {
    path: '/valentine-group-b/',
    name: 'valentineGroupB.index',
    component: () => import(
      '@/pages/CheckoutPages/ValentineCR/Index'
    ),
    children: [
      {
        path: '',
        name: 'valentineGroupB.start',
        component: () => import('@/pages/CheckoutPages/ValentineCR/Start')
      },
      {
        path: 'payment/:offerId',
        name: 'valentineGroupB.payment',
        component: () => import('@/pages/CheckoutPages/ValentineCR/Payment')
      }
    ],
    meta: {
      type: 'external_valentine_inst_group_b',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false
    }
  },

  {
    path: '/unlimited-offer/',
    name: 'unlimited.index',
    component: () => import(
      '@/pages/CheckoutPages/UnlimitedV2/Index'
    ),
    children: [
      {
        path: '',
        name: 'unlimited.start',
        component: () => import('@/pages/CheckoutPages/UnlimitedV2/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'unlimited.register',
        component: () => import('@/pages/CheckoutPages/UnlimitedV2/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isAuth = store.getters['auth/isAuthenticated']
          if (isAuth) {
            return next('/unlimited-offer/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'unlimited.payment',
        component: () => import('@/pages/CheckoutPages/UnlimitedV2/Payment')
      }
    ],
    meta: {
      type: 'external_unlimited_offer',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false
    }
  },
  {
    path: '/unlimited-offer-update-10',
    name: 'unlimitedOffer.index',
    component: () => import(
      '@/pages/CheckoutPages/UnlimitedV2/Index'
    ),
    children: [
      {
        path: '',
        name: 'unlimitedOffer.start',
        component: () => import('@/pages/CheckoutPages/UnlimitedV2/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'unlimitedOffer.register',
        component: () => import('@/pages/CheckoutPages/UnlimitedV2/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isAuth = store.getters['auth/isAuthenticated']
          if (isAuth) {
            return next('/unlimited-offer-update-10/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'unlimitedOffer.payment',
        component: () => import('@/pages/CheckoutPages/UnlimitedV2/Payment')
      }
    ],
    meta: {
      type: 'external_unlimited_offer_update_10',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      twoCheckout: true,
      planGroup: 'unlimitedOfferPlans',
      discount: 70
    }
  },
  {
    path: '/unlimited-2checkout/',
    name: 'unlimitedTwoCheckout.index',
    component: () => import(
      '@/pages/CheckoutPages/UnlimitedV2/Index'
    ),
    children: [
      {
        path: '',
        name: 'unlimitedTwoCheckout.start',
        component: () => import('@/pages/CheckoutPages/UnlimitedV2/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'unlimitedTwoCheckout.register',
        component: () => import('@/pages/CheckoutPages/UnlimitedV2/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isAuth = store.getters['auth/isAuthenticated']
          if (isAuth) {
            return next('/unlimited-2checkout/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'unlimitedTwoCheckout.payment',
        component: () => import('@/pages/CheckoutPages/UnlimitedV2/Payment')
      }
    ],
    meta: {
      type: 'external_unlimited_2checkout',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      twoCheckout: true
    }
  },
  {
    path: '/unlimited-offer-update/',
    name: 'unlimitedOfferUpdate.index',
    component: () => import(
      '@/pages/CheckoutPages/UnlimitedV2/Index'
    ),
    children: [
      {
        path: '',
        name: 'unlimitedOfferUpdate.start',
        component: () => import('@/pages/CheckoutPages/UnlimitedV2/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'unlimitedOfferUpdate.register',
        component: () => import('@/pages/CheckoutPages/UnlimitedV2/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isAuth = store.getters['auth/isAuthenticated']
          if (isAuth) {
            return next('/unlimited-offer-update/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'unlimitedOfferUpdate.payment',
        component: () => import('@/pages/CheckoutPages/UnlimitedV2/Payment')
      }
    ],
    meta: {
      type: 'external_unlimited_offer_update',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      twoCheckout: true,
      planGroup: 'unlimitedUpdate',
      discount: 70
    }
  },

  {
    path: '/unlimited-offer-welcome/',
    name: 'unlimitedWelcome.index',
    component: () => import(
      '@/pages/CheckoutPages/UnlimitedV2/Index'
    ),
    children: [
      {
        path: '',
        name: 'unlimitedWelcome.start',
        component: () => import('@/pages/CheckoutPages/UnlimitedV2/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'unlimitedWelcome.register',
        component: () => import('@/pages/CheckoutPages/UnlimitedV2/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isAuth = store.getters['auth/isAuthenticated']
          if (isAuth) {
            return next('/unlimited-offer-welcome/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'unlimitedWelcome.payment',
        component: () => import('@/pages/CheckoutPages/UnlimitedV2/Payment')
      }
    ],
    meta: {
      type: 'external_unlimited_offer_welcome',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false
    }
  },

  {
    path: '/spring-sale/',
    name: 'springSale.index',
    component: () => import(
      '@/pages/CheckoutPages/SpringSale/Index'
    ),
    children: [
      {
        path: '',
        name: 'springSale.start',
        component: () => import('@/pages/CheckoutPages/SpringSale/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'springSale.register',
        component: () => import('@/pages/CheckoutPages/SpringSale/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isaAuth = store.getters['auth/isAuthenticated']
          if (isaAuth) {
            return next('/spring-sale/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'springSale.payment',
        component: () => import('@/pages/CheckoutPages/SpringSale/Payment')
      }
    ],
    meta: {
      type: 'external_spring_sale',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false
    }
  },
  {
    path: '/spring-sale-inst/',
    name: 'springSaleInst.index',
    component: () => import(
      '@/pages/CheckoutPages/SpringSale/Index'
    ),
    children: [
      {
        path: '',
        name: 'springSaleInst.start',
        component: () => import('@/pages/CheckoutPages/SpringSale/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'springSaleInst.register',
        component: () => import('@/pages/CheckoutPages/SpringSale/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isaAuth = store.getters['auth/isAuthenticated']
          if (isaAuth) {
            return next('/spring-sale-inst/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'springSaleInst.payment',
        component: () => import('@/pages/CheckoutPages/SpringSale/Payment')
      }
    ],
    meta: {
      type: 'external_spring_sale_inst',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false
    }
  },

  {
    path: '/spring-sale-marketing/',
    name: 'springSaleMarketing.index',
    component: () => import(
      '@/pages/CheckoutPages/SpringSale/Index'
    ),
    children: [
      {
        path: '',
        name: 'springSaleMarketing.start',
        component: () => import('@/pages/CheckoutPages/SpringSale/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'springSaleMarketing.register',
        component: () => import('@/pages/CheckoutPages/SpringSale/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isaAuth = store.getters['auth/isAuthenticated']
          if (isaAuth) {
            return next('/spring-sale-marketing/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'springSaleMarketing.payment',
        component: () => import('@/pages/CheckoutPages/SpringSale/Payment')
      }
    ],
    meta: {
      type: 'external_spring_sale_marketing',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      customDiscount: 0.7
    }
  },

  {
    path: '/spring-sale-ab-control/',
    name: 'springSaleControl.index',
    component: () => import(
      '@/pages/CheckoutPages/SpringSale/Index'
    ),
    children: [
      {
        path: '',
        name: 'springSaleControl.start',
        component: () => import('@/pages/CheckoutPages/SpringSale/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'springSaleControl.register',
        component: () => import('@/pages/CheckoutPages/SpringSale/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isaAuth = store.getters['auth/isAuthenticated']
          if (isaAuth) {
            return next('/spring-sale-ab-control/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'springSaleControl.payment',
        component: () => import('@/pages/CheckoutPages/SpringSale/Payment')
      }
    ],
    meta: {
      planGroup: '70',
      type: 'external_spring_sale_ab6070_control',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false
    }
  },
  {
    path: '/spring-sale-ab-test/',
    name: 'springGroupB.index',
    component: () => import(
      '@/pages/CheckoutPages/SpringSale/Index'
    ),
    children: [
      {
        path: '',
        name: 'springGroupB.start',
        component: () => import('@/pages/CheckoutPages/SpringSale/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'springGroupB.register',
        component: () => import('@/pages/CheckoutPages/SpringSale/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isaAuth = store.getters['auth/isAuthenticated']
          if (isaAuth) {
            return next('/spring-sale-ab-test/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'springGroupB.payment',
        component: () => import('@/pages/CheckoutPages/SpringSale/Payment')
      }
    ],
    meta: {
      type: 'external_spring_sale_ab6070_groub_b',
      planGroup: '60',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false
    }
  },

  {
    path: '/spring-sale-web/',
    name: 'springSaleWeb.index',
    component: () => import(
      '@/pages/CheckoutPages/SpringSaleWeb/Index'
    ),
    children: [
      {
        path: '',
        name: 'springSaleWeb.start',
        component: () => import('@/pages/CheckoutPages/SpringSaleWeb/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'springSaleWeb.register',
        component: () => import('@/pages/CheckoutPages/SpringSaleWeb/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isaAuth = store.getters['auth/isAuthenticated']
          if (isaAuth) {
            return next('/spring-sale-web/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'springSaleWeb.payment',
        component: () => import('@/pages/CheckoutPages/SpringSaleWeb/Payment')
      }
    ],
    meta: {
      type: 'external_spring_sale_web',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false
    }
  },

  {
    path: '/easter-control/',
    name: 'easterControl.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('easterControl', '/easter-control/'),
    meta: {
      type: 'external_easter_control',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'easter'
    }
  },

  {
    path: '/easter-group-b/',
    name: 'easterGroupB.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('easterGroupB', '/easter-group-b/'),
    meta: {
      type: 'external_easter_group_b',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'easter'
    }
  },

  {
    path: '/unicorn-day/',
    name: 'unicornDay.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('unicornDay', '/unicorn-day/'),
    meta: {
      type: 'external_unicorn_day',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'unicorn'
    }
  },

  {
    path: '/high-five/',
    name: 'highFive.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('highFive', '/high-five/'),
    meta: {
      type: 'external_high_five',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'five'
    }
  },

  {
    path: '/english-day/',
    name: 'englishDay.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('englishDay', '/english-day/'),
    meta: {
      type: 'external_english_day',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'english'
    }
  },

  {
    path: '/penguin-day/',
    name: 'penguinDay.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('penguinDay', '/penguin-day/'),
    meta: {
      type: 'external_penguin',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'penguin'
    }
  },

  {
    path: '/superhero-day-control/',
    name: 'superHeroDayControl.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('superHeroDayControl', '/superhero-day-control/'),
    meta: {
      type: 'external_superhero_day_control',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'superHero'
    }
  },

  {
    path: '/superhero-day-test/',
    name: 'superHeroDayTest.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('superHeroDayTest', '/superhero-day-test/'),
    meta: {
      type: 'external_superhero_day_test',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'superHero66'
    }
  },

  {
    path: '/labor-day/',
    name: 'laborDay.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('laborDay', '/labor-day/'),
    meta: {
      type: 'external_labor_day',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'workers'
    }
  },

  {
    path: '/starwars-day/',
    name: 'starWarsDay.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('starWarsDay', '/starWarsDay/'),
    meta: {
      type: 'external_starwars_day',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'starWars'
    }
  },

  {
    path: '/laughter-day/',
    name: 'laughterDay.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('laughterDay', '/laughter-day/'),
    meta: {
      type: 'external_laughter_day',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'laughter'
    }
  },

  {
    path: '/mother-day-test/',
    name: 'motherDayTest.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('motherDayTest', '/mother-day-test/'),
    meta: {
      type: 'external_mother_day_test',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'motherDay'
    }
  },

  {
    path: '/mother-day-control/',
    name: 'motherDayControl.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('motherDayControl', '/mother-day-control/'),
    meta: {
      type: 'external_mother_day_control',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'motherDay'
    }
  },

  {
    path: '/light-day/',
    name: 'lightDay.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('lightDay', '/light-day/'),
    meta: {
      type: 'external_light_day',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'lightDay'
    }
  },

  {
    path: '/trial-cancel-offer/',
    name: 'trialCancel.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('trialCancel', '/trial-cancel-offer/'),
    meta: {
      type: 'external_trial_cancel',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'trialCancel',
      twoCheckout: true
    }
  },

  {
    path: '/summer-sale-checkout/',
    name: 'summerSaleCheckout.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('summerSaleCheckout', '/summer-sale-checkout/'),
    meta: {
      type: 'external_summer_sale_checkout',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'summerSale',
      twoCheckout: true
    }
  },

  {
    path: '/fashion-day-test/',
    name: 'fashionDayTest.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('fashionDayTest', '/fashion-day-test'),
    meta: {
      type: 'external_fashion_day_test',
      planGroup: 'twoCheckoutPlans',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'fashionDayTest',
      twoCheckout: true
    }
  },
  {
    path: '/fashion-day-control/',
    name: 'fashionDayControl.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('fashionDayControl', '/fashion-day-control'),
    meta: {
      type: 'external_fashion_day_control',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'fashionDay'
    }
  },

  {
    path: '/summer-sale-2checkout-control/',
    name: 'summerSaleCheckoutControl.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('summerSaleCheckoutControl', '/summer-sale-2checkout-control/'),
    meta: {
      type: 'external_summer_sale_2checkout_control',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'summerSale',
      twoCheckout: true
    }
  },

  {
    path: '/success/',
    name: 'success',
    component: () => import(
      '@/pages/Checkout/Success'
    ),
    meta: {
      auth: true
    }
  },

  {
    path: '/trial-cancel-offer-sad/',
    name: 'trialOfferSad.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('trialOfferSad', '/trial-cancel-offer-sad/'),
    meta: {
      type: 'external_trial_cancel_offer_sad',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'trialOfferSad',
      twoCheckout: true
    }
  },

  {
    path: '/billionaire-day-control/',
    name: 'billionaireDay.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('billionaireDay', '/billionaire-day-control/'),
    meta: {
      type: 'external_billionaire_day_control',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'billionaireDay'
    }
  },

  {
    path: '/billionaire-day-test/',
    name: 'billionaireDayTest.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('billionaireDayTest', '/billionaire-day-test/'),
    meta: {
      type: 'external_billionaire_day_test',
      planGroup: '5070',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'billionaireDay'
    }
  },

  {
    path: '/sherlock-day/',
    name: 'sherlockDay.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('sherlockDay', '/sherlock-day/'),
    meta: {
      type: 'external_sherlock_day',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'sherlockDay'
    }
  },

  {
    path: '/summer-sale-control/',
    name: 'summerSaleControl.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('summerSaleControl', '/summer-sale-control/'),
    meta: {
      type: 'external_summer_sale_control',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'summerSale'
    }
  },

  {
    path: '/summer-sale-test/',
    name: 'summerSaleTest.index',
    component: () => import(
      '@/pages/CheckoutPages/SummerSale/Index'
    ),
    children: [
      {
        path: '',
        name: 'summerSaleTest.start',
        component: () => import('@/pages/CheckoutPages/SummerSale/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'summerSaleTest.register',
        component: () => import('@/pages/CheckoutPages/SummerSale/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isaAuth = store.getters['auth/isAuthenticated']
          if (isaAuth) {
            return next('/summer-sale-test/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'summerSaleTest.payment',
        component: () => import('@/pages/CheckoutPages/SummerSale/Payment')
      }
    ],
    meta: {
      type: 'external_summer_sale_test',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false
    }
  },

  {
    path: '/nothing-to-fear/',
    name: 'nothingToFear.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('nothingToFear', '/nothing-to-fear/'),
    meta: {
      type: 'external_nothing_to_fear',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'nothingToFear'
    }
  },

  {
    path: '/creativity-day/',
    name: 'creativityDay.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('creativityDay', '/creativity-day/'),
    meta: {
      type: 'external_nothing_to_fear',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'creativityDay'
    }
  },

  {
    path: '/creativity-day-test/',
    name: 'creativityDayTest.index',
    component: () => import(
      '@/pages/CheckoutPages/UnlimitedStyle/Index'
    ),
    children: [
      {
        path: '',
        name: 'creativityDayTest.start',
        component: () => import('@/pages/CheckoutPages/UnlimitedStyle/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'creativityDayTest.register',
        component: () => import('@/pages/CheckoutPages/UnlimitedStyle/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isAuth = store.getters['auth/isAuthenticated']
          if (isAuth) {
            return next('/creativity-day-test/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'creativityDayTest.payment',
        component: () => import('@/pages/CheckoutPages/UnlimitedStyle/Payment')
      }
    ],
    meta: {
      type: 'external_creativity_day_test',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      planGroup: 'promoNoTrial70Year',
      discount: 70
    }
  },

  {
    path: '/environment-day-test/',
    name: 'environmentDayTest.index',
    component: () => import(
      '@/pages/CheckoutPages/UnlimitedStyle/Index'
    ),
    children: [
      {
        path: '',
        name: 'environmentDayTest.start',
        component: () => import('@/pages/CheckoutPages/UnlimitedStyle/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'environmentDayTest.register',
        component: () => import('@/pages/CheckoutPages/UnlimitedStyle/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isAuth = store.getters['auth/isAuthenticated']
          if (isAuth) {
            return next('/environment-day-test/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'environmentDayTest.payment',
        component: () => import('@/pages/CheckoutPages/UnlimitedStyle/Payment')
      }
    ],
    meta: {
      type: 'external_environment_day_test',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      discount: 50
    }
  },

  {
    path: '/parents-day-test/',
    name: 'parentsDayTest.index',
    component: () => import(
      '@/pages/CheckoutPages/UnlimitedStyle/Index'
    ),
    children: [
      {
        path: '',
        name: 'parentsDayTest.start',
        component: () => import('@/pages/CheckoutPages/UnlimitedStyle/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'parentsDayTest.register',
        component: () => import('@/pages/CheckoutPages/UnlimitedStyle/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isAuth = store.getters['auth/isAuthenticated']
          if (isAuth) {
            return next('/parents-day-test/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'parentsDayTest.payment',
        component: () => import('@/pages/CheckoutPages/UnlimitedStyle/Payment')
      }
    ],
    meta: {
      type: 'external_parents_day_test',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      discount: 70,
      planGroup: 'promoNoTrial70Year',
      twoCheckout: true
    }
  },

  {
    path: '/parents-day-control/',
    name: 'parentsDayControl.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('parentsDayControl', '/parents-day-control/'),
    meta: {
      type: 'external_parents_day_control',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'parentsDay',
      twoCheckout: true
    }
  },

  {
    path: '/repeat-day/',
    name: 'repeatDay.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('repeatDay', '/repeat-day/'),
    meta: {
      type: 'external_repeat_day',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'repeatDay'
    }
  },

  {
    path: '/environment-day-control/',
    name: 'environmentDayControl.index',
    component: () => import(
      '@/pages/CheckoutPages/EnvironmentDayControl/Index'
    ),
    children: [
      {
        path: '',
        name: 'environmentDayControl.start',
        component: () => import('@/pages/CheckoutPages/EnvironmentDayControl/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'environmentDayControl.register',
        component: () => import('@/pages/CheckoutPages/EnvironmentDayControl/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isaAuth = store.getters['auth/isAuthenticated']
          if (isaAuth) {
            return next('/environment-day-control/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'environmentDayControl.payment',
        component: () => import('@/pages/CheckoutPages/EnvironmentDayControl/Payment')
      }
    ],
    meta: {
      type: 'external_environment_day_control',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'environmentControl',
      discount: 50
    }
  },

  {
    path: '/summer-sale-inst/',
    name: 'summerSaleInst.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('summerSaleInst', '/summer-sale-inst/'),
    meta: {
      type: 'external_summer_sale_inst',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'summerSale'
    }
  },

  {
    path: '/summer-sale/',
    name: 'summerSale.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('summerSale', '/summer-sale/'),
    meta: {
      type: 'external_summer_sale',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'summerSale'
    }
  },

  {
    path: '/beautiful-day-control/',
    name: 'beautifulDayControl.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('beautifulDayControl', '/beautiful-day-control/'),
    meta: {
      type: 'external_beautiful_control',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'beautifulDay'
    }
  },
  {
    path: '/beautiful-day-test/',
    name: 'beautifulDayTest.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('beautifulDayTest', '/beautiful-day-test/'),
    meta: {
      type: 'external_beautiful_test',
      planGroup: 'summerSaleTestPlans',
      experiment_layer: 'external',
      hasTrial: true,
      auth: false,
      theme: 'beautifulDayTest'
    }
  },

  {
    path: '/juggling-day-control/',
    name: 'jugglingDayControl.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('jugglingDayControl', '/juggling-day-control/'),
    meta: {
      type: 'external_juggling_day_control',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: true,
      auth: false,
      theme: 'juggling'
    }
  },
  {
    path: '/juggling-day-test/',
    name: 'jugglingDayTest.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('jugglingDayTest', '/juggling-day-test/'),
    meta: {
      type: 'external_juggling_day_test',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: true,
      auth: false,
      theme: 'juggling',
      twoCheckout: true
    }
  },

  {
    path: '/music-day-test/',
    name: 'musicDayTest.index',
    component: () => import(
      '@/pages/CheckoutPages/UnlimitedStyle/Index'
    ),
    children: [
      {
        path: '',
        name: 'musicDayTest.start',
        component: () => import('@/pages/CheckoutPages/UnlimitedStyle/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'musicDayTest.register',
        component: () => import('@/pages/CheckoutPages/UnlimitedStyle/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isAuth = store.getters['auth/isAuthenticated']
          if (isAuth) {
            return next('/music-day-test/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'musicDayTest.payment',
        component: () => import('@/pages/CheckoutPages/UnlimitedStyle/Payment')
      }
    ],
    meta: {
      type: 'external_music_day_test',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      planGroup: 'promoNoTrial70Year',
      discount: 70
    }
  },

  {
    path: '/music-day-control/',
    name: 'musicDayControl.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('musicDayControl', '/music-day-control/'),
    meta: {
      type: 'external_music_day',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'musicDay'
    }
  },

  {
    path: '/social-media-day-control/',
    name: 'socialMediaDayControl.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('socialMediaDayControl', '/social-media-day-control/'),
    meta: {
      type: 'external_social_media_day_control',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'socialMediaDay'
    }
  },

  {
    path: '/alice-day/',
    name: 'aliceDay.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('aliceDay', '/alice-day/'),
    meta: {
      type: 'external_alice_day',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'aliceDay'
    }
  },

  {
    path: '/summer-sale_6promo/',
    name: 'summerSalePromo.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('summerSalePromo', '/summer-sale_6promo/'),
    meta: {
      type: 'external_summer_sale_6promo',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'summerSale66'
    }
  },

  {
    path: '/chocolate-day/',
    name: 'chocolateDay.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('chocolateDay', '/chocolate-day/'),
    meta: {
      type: 'external_chocolate_day',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'chocolateDay'
    }
  },

  {
    path: '/give-away-day-control/',
    name: 'giveAwayDayControl.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('giveAwayDayControl', '/give-away-day-control/'),
    meta: {
      type: 'external_give_away_day_control',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'giveAwayDay'
    }
  },

  {
    path: '/give-away-day-test/',
    name: 'giveAwayDayTest.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('giveAwayDayTest', '/give-away-day-test/'),
    meta: {
      type: 'external_give_away_day_test',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'giveAwayDay',
      twoCheckout: true
    }
  },

  {
    path: '/emoji-day/',
    name: 'emojiDay.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('emojiDay', '/emoji-day/'),
    meta: {
      type: 'external_emoji_day',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'emojiDay'
    }
  },

  {
    path: '/summer_sale_6promo_2checkout/',
    name: 'summerSalePromoTwoCheckout.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('summerSalePromoTwoCheckout', '/summer-sale_6promo_2checkout/'),
    meta: {
      type: 'external_summer_sale_6promo_2checkout',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'summerSale66',
      twoCheckout: true
    }
  },

  {
    path: '/summer-sale-6promo_inst/',
    name: 'summerSalePromoInst.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('summerSalePromoInst', '/summer-sale-6promo_inst/'),
    meta: {
      type: 'external_summer_sale_6promo_inst',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'summerSale66',
      withTierSupport: true
    }
  },

  {
    path: '/summer-sale-6promo_youtube/',
    name: 'summerSalePromoYoutube.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('summerSalePromoYoutube', '/summer-sale-6promo_youtube/'),
    meta: {
      type: 'external_summer_sale_6promo_youtube',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'summerSale66',
      withTierSupport: true
    }
  },

  {
    path: '/wine-cheese-day/',
    name: 'wineCheeseDay.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('wineCheeseDay', '/wine-cheese-day/'),
    meta: {
      type: 'external_wine_cheese_day',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'wineCheeseDay'
    }
  },
  {
    path: '/summer-sale-internal-promo/',
    name: 'summerSaleInternalPromo.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('summerSaleInternalPromo', '/summer-sale-internal-promo/'),
    meta: {
      type: 'external_summer_sale_internal_promo',
      planGroup: 'twoCheckoutPlans',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'summerSaleTest',
      twoCheckout: true
    }
  },

  {
    path: '/summer-sale_9_3/',
    name: 'summerSaleNinetyThree.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('summerSaleNinetyThree', '/summer-sale_9_3/'),
    meta: {
      type: 'external_summer_sale_9_3',
      planGroup: 'summerSaleNinetyThreePlans',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'summerSaleNinetyThree',
      twoCheckout: true
    }
  },
  {
    path: '/summer-sale_12_12/',
    name: 'summerSaleТwelve.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('summerSaleТwelve', '/summer-sale_12_12/'),
    meta: {
      type: 'external_summer_sale_12_12',
      planGroup: 'summerSaleТwelvePlans',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'summerSaleТwelve',
      twoCheckout: true
    }
  },

  {
    path: '/two-offer-promo',
    name: 'twoOfferPromo.index',
    component: () => import(
      '@/pages/CheckoutPages/TwoOfferPromo/Index'
    ),
    children: [
      {
        path: '',
        name: 'twoOfferPromo.start',
        component: () => import('@/pages/CheckoutPages/TwoOfferPromo/Start.vue')
      },
      {
        path: 'registration/:offerId',
        name: 'twoOfferPromo.register',
        component: () => import('@/pages/CheckoutPages/TwoOfferPromo/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isaAuth = store.getters['auth/isAuthenticated']
          if (isaAuth) {
            return next('/two-offer-promo/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'twoOfferPromo.payment',
        component: () => import('@/pages/CheckoutPages/TwoOfferPromo/Payment')
      }
    ],
    meta: {
      type: 'external_two_offer',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      planGroup: 'twoOfferPromoPlans',
      twoCheckout: true
    }
  },

  {
    path: '/promo-sale-web/',
    name: 'promoSaleWeb.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('promoSaleWeb', '/promo-sale-web/'),
    meta: {
      type: 'external_promo_sale_web',
      planGroup: '70',
      experiment_layer: 'conversion',
      hasTrial: false,
      auth: false,
      theme: 'autumnSale70',
      twoCheckout: true
    }
  },

  {
    path: '/unlimited-black-friday/',
    name: 'unlimitedBlackFriday.index',
    component: () => import(
      '@/pages/CheckoutPages/UnlimitedV2/Index'
    ),
    children: [
      {
        path: '',
        name: 'unlimitedBlackFriday.start',
        component: () => import('@/pages/CheckoutPages/UnlimitedV2/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'unlimitedBlackFriday.register',
        component: () => import('@/pages/CheckoutPages/UnlimitedV2/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isAuth = store.getters['auth/isAuthenticated']
          if (isAuth) {
            return next('/unlimited-black-friday/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'unlimitedBlackFriday.payment',
        component: () => import('@/pages/CheckoutPages/UnlimitedV2/Payment')
      }
    ],
    meta: {
      type: 'external_unlimited_black_friday',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      twoCheckout: true
    }
  },

  {
    path: '/cyber-monday-12-12/',
    name: 'cyberMonday.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('cyberMonday', '/cyber-monday-12-12/'),
    meta: {
      type: 'external_cyber_monday_12_12',
      planGroup: 'twelvePlusTwelve',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'cyberMonday',
      twoCheckout: true
    }
  },

  {
    path: '/new-year-70/',
    name: 'newYear70.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('newYear70', '/new-year-70/'),
    meta: {
      type: 'external_new_year_70',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'newYear70',
      twoCheckout: true
    }
  },

  {
    path: '/new-year-3-9-focus-2/',
    name: 'newYearFocus2.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('newYearFocus2', '/new-year-3-9-focus-2/'),
    meta: {
      type: 'external_new_year_3_9_focus_2',
      planGroup: 'seventyAndThreePlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'newYearFocus2',
      twoCheckout: true
    }
  },

  {
    path: '/new-year-3-9/',
    name: 'newYear39.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('newYear39', '/new-year-3-9/'),
    meta: {
      type: 'external_new_year_3_9',
      planGroup: 'seventyAndThreePlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'newYear39',
      twoCheckout: true
    }
  },

  {
    path: '/new-year-sale-6-6/',
    name: 'newYearSale66.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('newYearSale66', '/new-year-sale-6-6/'),
    meta: {
      type: 'external_new_year_sale_6_6',
      planGroup: 'sixPlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'newYearSale66',
      twoCheckout: true
    }
  },

  {
    path: '/new-year-12-12/',
    name: 'newYear24.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('newYear24', '/new-year-12-12/'),
    meta: {
      type: 'external_new_year_12_12',
      planGroup: 'twelvePlusTwelve',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'newYear24',
      twoCheckout: true
    }
  },

  {
    path: '/new-year-sale-6-6-one-offer/',
    name: 'autumnSale66OneOffer.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('autumnSale66OneOffer', '/new-year-sale-6-6-one-offer/'),
    meta: {
      type: 'external_new_year_sale_6_6_one_offer',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'newYearSale66',
      twoCheckout: true
    }
  },

  {
    path: '/new-year-focus-month/',
    name: 'newYearFocusMonth.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('newYearFocusMonth', '/new-year-focus-month/'),
    meta: {
      type: 'external_new_year_focus_month',
      planGroup: 'autumnSaleYearMonthPlans',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'newYear39',
      twoCheckout: true
    }
  },

  {
    path: '/new-year-social-3-9/',
    name: 'newYearSocial39.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('newYearSocial39', '/new-year-social-3-9/'),
    meta: {
      type: 'external_new_year_social_3_9',
      planGroup: 'seventyAndThreePlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'newYear39',
      withTierSupport: true,
      twoCheckout: true,
      applePay: true
    }
  },

  {
    path: '/paywall-v2m-v1/',
    name: 'paywallV2.index',
    component: () => import(
      '@/pages/CheckoutPages/PaywallV2/Index'
    ),
    children: [
      {
        path: '',
        name: 'paywallV2.start',
        component: () => import('@/pages/CheckoutPages/PaywallV2/Start')
      },
      {
        path: 'payment/:offerId',
        name: 'paywallV2.payment',
        component: () => import('@/pages/CheckoutPages/PaywallV2/Payment')
      }
    ],
    meta: {
      experiment_layer: 'external',
      hasTrial: false,
      auth: true
    }
  },

  {
    path: '/paywall-promo-v2m-v1',
    name: 'paywallPromoV2.index',
    component: () => import(
      '@/pages/CheckoutPages/PaywallPromoV2/Index'
    ),
    children: [
      {
        path: '',
        name: 'paywallPromoV2.start',
        component: () => import('@/pages/CheckoutPages/PaywallPromoV2/Start')
      },
      {
        path: 'payment/:offerId',
        name: 'paywallPromoV2.payment',
        component: () => import('@/pages/CheckoutPages/PaywallPromoV2/Payment')
      }
    ],
    meta: {
      experiment_layer: 'external',
      hasTrial: false,
      auth: true
    }
  },

  {
    path: '/paywall-success-v1',
    name: 'paywallSuccess.index',
    component: () => import(
      '@/pages/CheckoutPages/PaywallV2/Success'
    ),
    meta: {
      auth: true
    }
  },

  {
    path: '/paywall-download-v1',
    name: 'paywallDownload.index',
    component: () => import(
      '@/pages/CheckoutPages/PaywallV2/Download'
    ),
    meta: {
      auth: true
    }
  },

  {
    path: '/winter-sale-one-3-9/',
    name: 'winterSaleOne39.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('winterSaleOne39', '/winter-sale-one-3-9/'),
    meta: {
      type: 'external_winter_sale_one_offer_3_9',
      planGroup: 'seventyAndThreePlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'winterSaleOne39',
      twoCheckout: true
    }
  },

  {
    path: '/winter-sale-6-6/',
    name: 'winterSale66.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('winterSale66', '/winter-sale-6-6/'),
    meta: {
      type: 'external_winter_sale_6_6',
      planGroup: 'sixPlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'winterSale66',
      twoCheckout: true
    }
  },

  {
    path: '/winter-sale-3-9/',
    name: 'winterSale39.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('winterSale39', '/winter-sale-3-9/'),
    meta: {
      type: 'external_winter_sale_3_9',
      planGroup: 'seventyAndThreePlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'winterSale39',
      twoCheckout: true
    }
  },

  {
    path: '/winter-sale-12-12/',
    name: 'winterSale24.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('winterSale24', '/winter-sale-12-12/'),
    meta: {
      type: 'external_winter_sale_12_12',
      planGroup: 'twelvePlusTwelve',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'winterSale24',
      twoCheckout: true
    }
  },

  {
    path: '/winter-sale-70/',
    name: 'winterSale70.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('winterSale70', '/winter-sale-70/'),
    meta: {
      type: 'external_winter_sale_70',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'winterSale70',
      twoCheckout: true
    }
  },

  {
    path: '/winter-sale-social-6-6/',
    name: 'winterSaleSocial66.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('winterSaleSocial66', '/winter-sale-social-6-6/'),
    meta: {
      type: 'external_winter_sale_6_6_social',
      planGroup: 'sixPlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'winterSale66',
      withTierSupport: true,
      twoCheckout: true,
      applePay: true
    }
  },

  {
    path: '/chinese-new-year-3-9/',
    name: 'chineseNewYear39.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('chineseNewYear39', '/chinese-new-year-3-9/'),
    meta: {
      type: 'external_chinese_new_year_3_9',
      planGroup: 'seventyAndThreePlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'chineseNewYear39',
      twoCheckout: true,
      applePay: true
    }
  },

  {
    path: '/february-14-70/',
    name: 'february1470.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('february1470', '/february-14-70/'),
    meta: {
      type: 'external_february_14_70',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'february1470',
      twoCheckout: true,
      applePay: true
    }
  },

  {
    path: '/winter-sale-80/',
    name: 'winterSale80.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('winterSale80', '/winter-sale-80/'),
    meta: {
      type: 'external_winter_sale_80',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'winterSale80',
      twoCheckout: true
    }
  },
  {
    path: '/spring-sale-3-9/',
    name: 'springSale39.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('springSale39', '/spring-sale-3-9/'),
    meta: {
      type: 'external_spring_sale_3_9',
      planGroup: 'seventyAndThreePlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'springSale39',
      twoCheckout: true,
      applePay: true
    }
  },

  {
    path: '/spring-sale-3-9-checkout-test/',
    name: 'springSale39CheckoutTest.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('springSale39CheckoutTest', '/spring-sale-3-9-checkout-test/'),
    meta: {
      type: 'external_spring_3_9_checkout_test',
      planGroup: 'seventyAndThreePlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'springSale39',
      twoCheckout: true,
      checkoutType: 'convertPlus'
    }
  },

  {
    path: '/spring-sale-3-9-checkout-control/',
    name: 'springSale39CheckoutControl.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('springSale39CheckoutControl', '/spring-sale-3-9-checkout-control/'),
    meta: {
      type: 'external_spring_3_9_checkout_control',
      planGroup: 'seventyAndThreePlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'springSale39',
      twoCheckout: true,
      applePay: true,
      orderStyle: 'nLWwnZWpmHI='
    }
  },

  {
    path: '/spring-sale-70/',
    name: 'springSale70.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('springSale70', '/spring-sale-70/'),
    meta: {
      type: 'external_spring_sale_70',
      planGroup: '70',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'springSale70',
      twoCheckout: true,
      applePay: true
    }
  },
  {
    path: '/spring-sale-6-6/',
    name: 'springSale66.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('springSale66', '/spring-sale-6-6/'),
    meta: {
      type: 'external_spring_sale_6_6',
      planGroup: 'sixPlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'springSale66',
      twoCheckout: true,
      applePay: true
    }
  },

  {
    path: '/spring-sale-12-12/',
    name: 'springSale24.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('springSale24', '/spring-sale-12-12/'),
    meta: {
      type: 'external_spring_sale_12_12',
      planGroup: 'twelvePlusTwelve',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'springSale24',
      twoCheckout: true,
      applePay: true
    }
  },

  {
    path: '/spring-sale-50',
    name: 'springSale50.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('springSale50', '/spring-sale-50/'),
    meta: {
      type: 'external_spring_sale_50',
      planGroup: '50',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'springSale50',
      twoCheckout: true,
      applePay: true
    }
  },

  {
    path: '/spring-sale-social-6-6/',
    name: 'springSaleSocial66.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('springSaleSocial66', '/spring-sale-social-6-6/'),
    meta: {
      type: 'external_spring_sale_6_6_social',
      planGroup: 'sixPlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'springSale66',
      withTierSupport: true,
      twoCheckout: true,
      applePay: true
    }
  },

  {
    path: '/spring-sale-social-3-9/',
    name: 'springSaleSocial39.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('springSaleSocial39', '/spring-sale-social-3-9/'),
    meta: {
      type: 'external_spring_sale_3_9_social',
      planGroup: 'seventyAndThreePlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'springSale39',
      withTierSupport: true,
      twoCheckout: true,
      applePay: true
    }
  },

  {
    path: '/one-offer-blnk-70',
    name: 'oneOfferBlnk70.index',
    component: () => import(
      '@/pages/CheckoutPages/OneOfferBlnk70/Index.vue'
    ),
    children: [
      {
        path: '',
        name: 'oneOfferBlnk70.start',
        component: () => import('@/pages/CheckoutPages/OneOfferBlnk70/Start.vue')
      },
      {
        path: 'registration/:offerId',
        name: 'oneOfferBlnk70.register',
        component: () => import('@/pages/CheckoutPages/layouts/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isaAuth = store.getters['auth/isAuthenticated']
          if (isaAuth) {
            return next('/one-offer-blnk-70/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'oneOfferBlnk70.payment',
        component: () => import('@/pages/CheckoutPages/layouts/Payment')
      }
    ],
    meta: {
      type: 'external_one_offer_blnk_70',
      planGroup: 'seventyAndThreePlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      twoCheckout: true,
      applePay: true
    }
  },

  {
    path: '/spring-sale-12-12-checkout-test/',
    name: 'springSale24CheckoutTest.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('springSale24CheckoutTest', '/spring-sale-12-12-checkout-test/'),
    meta: {
      type: 'external_spring_12_12_checkout_test',
      planGroup: 'springSale24',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'springSale24',
      twoCheckout: true,
      checkoutType: 'convertPlus'
    }
  },

  {
    path: '/onboarding-promo-6-6/',
    name: 'onboardinPromo66.index',
    component: () => import(
      '@/pages/CheckoutPages/OnboardingPromo66/Index'
    ),
    children: [
      {
        path: '',
        name: 'onboardinPromo66.start',
        component: () => import('@/pages/CheckoutPages/OnboardingPromo66/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'onboardinPromo66.register',
        component: () => import('@/pages/CheckoutPages/layouts/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isAuth = store.getters['auth/isAuthenticated']
          if (isAuth) {
            return next('/onboarding-promo-6-6/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'onboardinPromo66.payment',
        component: () => import('@/pages/CheckoutPages/layouts/Payment')
      }
    ],
    meta: {
      type: 'external_onboarding_promo_6_6',
      planGroup: 'sixPlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      twoCheckout: true,
      applePay: true
    }
  },

  {
    path: '/onboarding-promo-70/',
    name: 'onboardinPromo70.index',
    component: () => import(
      '@/pages/CheckoutPages/OnboardingPromo66/Index'
    ),
    children: [
      {
        path: '',
        name: 'onboardinPromo70.start',
        component: () => import('@/pages/CheckoutPages/OnboardingPromo66/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'onboardinPromo70.register',
        component: () => import('@/pages/CheckoutPages/layouts/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isAuth = store.getters['auth/isAuthenticated']
          if (isAuth) {
            return next('/onboarding-promo-70/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'onboardinPromo70.payment',
        component: () => import('@/pages/CheckoutPages/layouts/Payment')
      }
    ],
    meta: {
      type: 'external_onboarding_promo_70',
      planGroup: 'promoNoTrial70Plans',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      twoCheckout: true,
      applePay: true
    }
  },

  {
    path: '/onboarding-promo-12-12/',
    name: 'onboardinPromo12.index',
    component: () => import(
      '@/pages/CheckoutPages/OnboardingPromo66/Index'
    ),
    children: [
      {
        path: '',
        name: 'onboardinPromo12.start',
        component: () => import('@/pages/CheckoutPages/OnboardingPromo66/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'onboardinPromo12.register',
        component: () => import('@/pages/CheckoutPages/layouts/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isAuth = store.getters['auth/isAuthenticated']
          if (isAuth) {
            return next('/onboarding-promo-12-12/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'onboardinPromo12.payment',
        component: () => import('@/pages/CheckoutPages/layouts/Payment')
      }
    ],
    meta: {
      type: 'external_onboarding_promo_12_12',
      planGroup: 'twelvePlusTwelve',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      twoCheckout: true,
      applePay: true
    }
  },

  {
    path: '/onboarding-promo-3-9',
    name: 'onboardinPromo39.index',
    component: () => import(
      '@/pages/CheckoutPages/OnboardingPromo66/Index'
    ),
    children: [
      {
        path: '',
        name: 'onboardinPromo39.start',
        component: () => import('@/pages/CheckoutPages/OnboardingPromo66/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'onboardinPromo39.register',
        component: () => import('@/pages/CheckoutPages/layouts/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isAuth = store.getters['auth/isAuthenticated']
          if (isAuth) {
            return next('/onboarding-promo-3-9')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'onboardinPromo39.payment',
        component: () => import('@/pages/CheckoutPages/layouts/Payment')
      }
    ],
    meta: {
      type: 'external_onboarding_promo_3_9',
      planGroup: 'seventyAndThreePlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      twoCheckout: true,
      applePay: true
    }
  },

  {
    path: '/spring-sale-6-6-checkout-test/',
    name: 'springSale66CheckoutTest.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('springSale39CheckoutTest', '/spring-sale-6-6-checkout-test/'),
    meta: {
      type: 'external_spring_6_6_checkout_test',
      planGroup: 'sixPlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'springSale66',
      twoCheckout: true,
      checkoutType: 'convertPlus'
    }
  },

  {
    path: '/spring-sale-6-6-checkout-control/',
    name: 'springSale66CheckoutControl.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('springSale66CheckoutControl', '/spring-sale-6-6-checkout-control/'),
    meta: {
      type: 'external_spring_6_6_checkout_control',
      planGroup: 'sixPlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'springSale66',
      twoCheckout: true,
      applePay: true,
      orderStyle: 'nLWwnZWpmHI='
    }
  },

  {
    path: '/spring-sale-12-12-checkout-control/',
    name: 'springSale24CheckoutControl.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('springSale24CheckoutControl', '/spring-sale-12-12-checkout-control/'),
    meta: {
      type: 'external_spring_12_12_checkout_control',
      planGroup: 'springSale24',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'springSale24',
      twoCheckout: true,
      applePay: true,
      orderStyle: 'nLWwnZWpmHI='
    }
  },

  {
    path: '/summer-3-9-control/',
    name: 'summerControl39.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('summerControl39', '/summer-3-9-control/'),
    meta: {
      type: 'external_summer_sale_3_9_control',
      planGroup: 'seventyAndThreePlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'summerControl39',
      twoCheckout: true,
      applePay: true,
      orderStyle: 'nLWwnZWpmHI='
    }
  },

  {
    path: '/summer-70-control/',
    name: 'summerControl70.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('summerControl70', '/summer-70-control/'),
    meta: {
      type: 'external_summer_sale_70_control',
      planGroup: 'seventyAndThreePlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'summerControl70',
      twoCheckout: true,
      applePay: true,
      orderStyle: 'nLWwnZWpmHI='
    }
  },

  {
    path: '/summer-6-6-control/',
    name: 'summerControl66.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('summerControl66', '/summer-6-6-control/'),
    meta: {
      type: 'external_summer_sale_6_6_control',
      planGroup: 'sixPlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'summerControl66',
      twoCheckout: true,
      applePay: true,
      orderStyle: 'nLWwnZWpmHI='
    }
  },

  {
    path: '/summer-12-12-control/',
    name: 'summerControl24.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('summerControl24', '/summer-12-12-control/'),
    meta: {
      type: 'external_summer_sale_12_12_control',
      planGroup: 'springSale24',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'summerControl24',
      twoCheckout: true,
      applePay: true,
      orderStyle: 'nLWwnZWpmHI='
    }
  },

  {
    path: '/summer-sale-3-9/',
    name: 'summerSale39.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('summerSale39', '/summer-sale-3-9/'),
    meta: {
      type: 'external_summer_sale_3_9',
      planGroup: 'seventyAndThreePlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'summerSale39',
      twoCheckout: true,
      applePay: true,
      checkoutType: 'convertPlus'
    }
  },

  {
    path: '/summer-sale-70/',
    name: 'summerSale70.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('summerSale70', '/summer-sale-70/'),
    meta: {
      type: 'external_summer_sale_70',
      planGroup: 'seventyAndThreePlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'summerSale70',
      twoCheckout: true,
      applePay: true,
      checkoutType: 'convertPlus'
    }
  },

  {
    path: '/summer-sale-12-12/',
    name: 'summerSale24.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('summerSale24', '/summer-sale-12-12/'),
    meta: {
      type: 'external_summer_sale_12_12',
      planGroup: 'summerSale24',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'summerSale24',
      twoCheckout: true,
      applePay: true,
      checkoutType: 'convertPlus'
    }
  },

  {
    path: '/summer-sale-6-6/',
    name: 'summerSale66.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('summerSale66', '/summer-sale-6-6/'),
    meta: {
      type: 'external_summer_sale_6_6',
      planGroup: 'sixPlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'summerSale66New',
      twoCheckout: true,
      applePay: true,
      checkoutType: 'convertPlus'
    }
  },

  {
    path: '/summer-sale-social-6-6/',
    name: 'summerSaleSocial66.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('summerSaleSocial66', '/summer-sale-social-6-6/'),
    meta: {
      type: 'external_summer_sale_6_6_social',
      planGroup: 'sixPlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'summerSale66New',
      withTierSupport: true,
      twoCheckout: true,
      applePay: true
    }
  },

  {
    path: '/onboarding-6-6/',
    name: 'onboarding66.index',
    component: () => import(
      '@/pages/CheckoutPages/Onboarding66/Index'
    ),
    children: [
      {
        path: '',
        name: 'onboarding66.start',
        component: () => import('@/pages/CheckoutPages/Onboarding66/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'onboarding66.register',
        component: () => import('@/pages/CheckoutPages/layouts/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isAuth = store.getters['auth/isAuthenticated']
          if (isAuth) {
            return next('/onboarding-6-6/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'onboarding66.payment',
        component: () => import('@/pages/CheckoutPages/layouts/Payment')
      }
    ],
    meta: {
      type: 'external_onboarding_promo_6_6_test',
      planGroup: 'sixPlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      twoCheckout: true,
      applePay: true
    }
  },

  {
    path: '/onboarding-3-9',
    name: 'onboarding39.index',
    component: () => import(
      '@/pages/CheckoutPages/Onboarding66/Index'
    ),
    children: [
      {
        path: '',
        name: 'onboarding39.start',
        component: () => import('@/pages/CheckoutPages/Onboarding66/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'onboarding39.register',
        component: () => import('@/pages/CheckoutPages/layouts/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isAuth = store.getters['auth/isAuthenticated']
          if (isAuth) {
            return next('/onboarding-3-9')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'onboarding39.payment',
        component: () => import('@/pages/CheckoutPages/layouts/Payment')
      }
    ],
    meta: {
      type: 'external_onboarding_promo_3_9_test',
      planGroup: 'seventyAndThreePlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      twoCheckout: true,
      applePay: true
    }
  },

  {
    path: '/onboarding-70/',
    name: 'onboarding70.index',
    component: () => import(
      '@/pages/CheckoutPages/Onboarding66/Index'
    ),
    children: [
      {
        path: '',
        name: 'onboarding70.start',
        component: () => import('@/pages/CheckoutPages/Onboarding66/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'onboarding70.register',
        component: () => import('@/pages/CheckoutPages/layouts/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isAuth = store.getters['auth/isAuthenticated']
          if (isAuth) {
            return next('/onboarding-70/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'onboarding70.payment',
        component: () => import('@/pages/CheckoutPages/layouts/Payment')
      }
    ],
    meta: {
      type: 'external_onboarding_promo_70_test',
      planGroup: 'promoNoTrial70Plans',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      twoCheckout: true,
      applePay: true
    }
  },

  {
    path: '/onboarding-12-12/',
    name: 'onboarding12.index',
    component: () => import(
      '@/pages/CheckoutPages/Onboarding66/Index'
    ),
    children: [
      {
        path: '',
        name: 'onboarding12.start',
        component: () => import('@/pages/CheckoutPages/Onboarding66/Start')
      },
      {
        path: 'registration/:offerId',
        name: 'onboarding12.register',
        component: () => import('@/pages/CheckoutPages/layouts/Registration'),
        meta: {
          auth: false
        },
        beforeEnter (to, from, next) {
          const isAuth = store.getters['auth/isAuthenticated']
          if (isAuth) {
            return next('/onboarding-12-12/')
          }
          next()
        }
      },
      {
        path: 'payment/:offerId',
        name: 'onboarding12.payment',
        component: () => import('@/pages/CheckoutPages/layouts/Payment')
      }
    ],
    meta: {
      type: 'external_onboarding_promo_12_12_test',
      planGroup: 'twelvePlusTwelve',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      twoCheckout: true,
      applePay: true
    }
  },

  {
    path: '/autumn-3-9/',
    name: 'autumnSale39.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('autumnSale39', '/autumn-3-9/'),
    meta: {
      type: 'external_autumn_sale_3_9',
      planGroup: 'seventyAndThreePlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'autumnSale39',
      twoCheckout: true,
      applePay: true
    }
  },

  {
    path: '/autumn-70/',
    name: 'autumnSale70.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('autumnSale70', '/autumn-70/'),
    meta: {
      type: 'external_autumn_sale_70',
      planGroup: 'seventyAndThreePlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'autumnSale70',
      twoCheckout: true,
      applePay: true
    }
  },
  {
    path: '/autumn-6-6/',
    name: 'autumnSale66.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('autumnSale66', '/autumn-6-6/'),
    meta: {
      type: 'external_autumn_sale_6_6',
      planGroup: 'sixPlusSix',
      theme: 'autumnSale66',
      hasTrial: false,
      auth: false,
      twoCheckout: true,
      applePay: true
    }
  },
  {
    path: '/autumn-12-12/',
    name: 'autumnSale1212.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('autumnSale1212', '/autumn-12-12/'),
    meta: {
      type: 'external_autumn_sale_12_12',
      planGroup: 'twelvePlusTwelve',
      hasTrial: false,
      auth: false,
      theme: 'autumnSale1212',
      twoCheckout: true,
      applePay: true
    }
  },
  {
    path: '/autumn-sale-social-6-6/',
    name: 'autumnSaleSocial66.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('autumnSaleSocial66', '/autumn-sale-social-6-6/'),
    meta: {
      type: 'external_autumn_sale_6_6_social',
      planGroup: 'sixPlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'autumnSale66',
      withTierSupport: true,
      twoCheckout: true,
      applePay: true
    }
  },
  {
    path: '/autumn-sale-social-3-9/',
    name: 'autumnSaleSocial39.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('autumnSaleSocial39', '/autumn-sale-social-3-9/'),
    meta: {
      type: 'external_autumn_sale_3_9_social',
      planGroup: 'seventyAndThreePlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'autumnSale39',
      withTierSupport: true,
      twoCheckout: true,
      applePay: true
    }
  },
  {
    path: '/blackfriday-3-9/',
    name: 'blackFriday39.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('blackFriday39', '/blackfriday-3-9/'),
    meta: {
      type: 'external_blackfriday_sale_3_9',
      planGroup: 'seventyAndThreePlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'blackFriday39',
      twoCheckout: true,
      applePay: true
    }
  },
  {
    path: '/blackfriday-70/',
    name: 'blackFriday70.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('blackFriday70', '/blackfriday-70/'),
    meta: {
      type: 'external_blackfriday_sale_70',
      planGroup: 'seventyAndThreePlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'blackFriday70',
      twoCheckout: true,
      applePay: true
    }
  },
  {
    path: '/blackfriday-6-6/',
    name: 'blackFriday66.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('blackFriday66', '/blackfriday-6-6/'),
    meta: {
      type: 'external_blackfriday_sale_6_6',
      planGroup: 'sixPlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'blackFriday66',
      twoCheckout: true,
      applePay: true
    }
  },
  {
    path: '/blackfriday-12-12/',
    name: 'blackFriday24.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('blackFriday24', '/blackfriday-12-12/'),
    meta: {
      type: 'external_blackfriday_sale_12_12',
      planGroup: 'springSale24',
      hasTrial: false,
      auth: false,
      theme: 'blackFriday24',
      twoCheckout: true,
      applePay: true
    }
  },
  {
    path: '/blackfriday-social-3-9/',
    name: 'blackFridaySocial39.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('blackFridaySocial39', '/blackfriday-social-3-9/'),
    meta: {
      type: 'external_blackfriday_3_9_social',
      planGroup: 'seventyAndThreePlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'blackFriday39',
      withTierSupport: true,
      twoCheckout: true,
      applePay: true
    }
  },
  {
    path: '/blackfriday-social-6-6/',
    name: 'blackFridaySocial66.index',
    component: () => import(
      '@/pages/CheckoutPages/layouts/Index'
    ),
    children: createChildren('blackFridaySocial66', '/blackfriday-social-6-6/'),
    meta: {
      type: 'external_blackfriday_6_6_social',
      planGroup: 'sixPlusSix',
      experiment_layer: 'external',
      hasTrial: false,
      auth: false,
      theme: 'blackFriday66',
      withTierSupport: true,
      twoCheckout: true,
      applePay: true
    }
  }
]
